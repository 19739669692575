import React from "react";
import { HStack, Center, Text, Box, Image } from "@chakra-ui/react";
import styled from "styled-components";
import FoodItem from "./FoodItem";
import { burger, wrap } from "./data";

export default function Home() {
  var colors = [
    {
      center: "#7CC5EC",
      outer: "#3D8ABA",
    },
    {
      center: "#F3FDA6",
      outer: "#8ABA3D",
    },
    {
      center: "#DDD6F3",
      outer: "#FAACA8",
    },
    {
      center: "#DDD6F3",
      outer: "#FAACA8",
    },
    {
      center: "#A988F7",
      outer: "#5F80F7",
    },
    {
      center: "#EECDA3",
      outer: "#EF629F",
    },
  ];

  var rand = Math.floor(Math.random() * colors.length);

  const randomStyle = {
    background: `radial-gradient(${colors[rand].center}, ${colors[rand].outer})`,
  };

  const [tab, setTab] = React.useState("burger");

  return (
    <Container style={randomStyle}>
      {/* <HStack
        position="absolute"
        top="5"
        left="0"
        right="0"
        justifyContent="center"
        zIndex="999999999"
      >
        <HStack
          p="7px"
          bg="#fff"
          borderRadius="30"
          alignSelf="center"
          justifyContent="space-evenly"
        >
          <Center
            borderRadius="full"
            transition="0.4s all"
            cursor="pointer"
            onMouseDown={() => setTab("burger")}
            bg={tab === "burger" ? colors[rand].outer : "#fff"}
            m="0 !important"
            w="100px"
            h="40px"
            alignSelf="stretch"
          >
            <Text fontFamily="MarkPro-Bold" fontWeight="600" color={tab === "burger" ? "#fff" : "#000"}>
              Burger
            </Text>
          </Center>
          <div width="5pX"></div>
          <Center
            borderRadius="full"
            transition="0.4s all"
            cursor="pointer"
            onMouseDown={() => setTab("wrap")}
            bg={tab === "burger" ? "#fff" : colors[rand].outer}
            m="0 !important"
            w="100px"
            h="40px"
            alignSelf="stretch"
          >
            <Text
              fontFamily="MarkPro-Bold"
              fontWeight="600"
              color={tab === "burger" ? "#000" : "#fff"}
            >
              Wrap
            </Text>
          </Center>
        </HStack>
      </HStack> */}

      <FoodItem
        co2={tab === "burger" ? burger.co2 : wrap.co2}
        serving={tab === "burger" ? burger.serving : wrap.serving}
        macros={tab === "burger" ? burger.macros : wrap.macros}
        ingredients={tab === "burger" ? burger.ingredients : wrap.ingredients}
        sources={tab === "burger" ? burger.sources : wrap.sources}
        imgContainer={
          <Box w="100%" h="100%" bg="#999">
            <Image
              display={tab === "burger" ? "flex" : "none"}
              src={burger.img}
            />
            <Image display={tab === "burger" ? "none" : "flex"} src={wrap.img} />
          </Box>
        }
      />
    </Container>
  );
}

const Container = styled.div`
  /* background: radial-gradient(colors, #3d8aba); */
  min-height: 100%;
  width: 100%;
  color: #fff;
`;
