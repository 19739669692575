import React from "react";
import "./App.css";
import "./normalize.css";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from "./Home"

function App() {
  return (
    <Router>
      {/* <Route path="/food/mangocheesecake" component={Home}></Route> */}
      <Route path="/*"><Home></Home></Route>
    </Router>
  );
}


export default App;
