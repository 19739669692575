import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Poppins-Medium.ttf'
import './fonts/Poppins-Regular.ttf'
import './fonts/Poppins-SemiBold.ttf'
import firebase from 'firebase'
import { ChakraProvider } from "@chakra-ui/react"

const firebaseConfig = {
  apiKey: "AIzaSyAdgNR0N5nqeSizRRucQv8yAN3paBCDNfI",
  authDomain: "organicboho-x-wemuda.firebaseapp.com",
  projectId: "organicboho-x-wemuda",
  storageBucket: "organicboho-x-wemuda.appspot.com",
  messagingSenderId: "745957151576",
  appId: "1:745957151576:web:1ae6d1af515b7f5d6e0b2d",
  measurementId: "G-HPEKLE2N1D"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
    <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
      
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
