import React from "react";
import "./App.css";
import "./normalize.css";
import styled from "styled-components";

export default function FoodItem(props) {

  return (
      <InnerContainer>
        {props.imgContainer}
        <StyledPaperBox>
          <StyledPaperBoxTitle>{props.co2}</StyledPaperBoxTitle>
          <StyledPaperBoxAmountSubtitle>
            {props.serving}
          </StyledPaperBoxAmountSubtitle>
        </StyledPaperBox>
        <MacroContainer>
          <StyledMacroTopContainer>
            <StyledMacroTitle>Macronutrients</StyledMacroTitle>
            <StyledMacroServingSize>Per 100g</StyledMacroServingSize>
          </StyledMacroTopContainer>
          {props.macros.map((item) => (
            <MacroRow key={item.title}>
              <MacroTitle
                style={
                  item.extended
                    ? { marginLeft: "10px" }
                    : { fontFamily: "MarkPro-Bold" }
                }
              >
                {item.title}
              </MacroTitle>
              <MacroValue
                style={
                  item.extended
                    ? { fontFamily: "MarkPro-Book" }
                    : { fontFamily: "MarkPro-Bold" }
                }
              >
                {item.value}
              </MacroValue>
            </MacroRow>
          ))}
        </MacroContainer>
        <MacroContainer>
          <StyledMacroTopContainer>
            <StyledMacroTitle>Ingredients</StyledMacroTitle>
            <StyledMacroServingSize></StyledMacroServingSize>
          </StyledMacroTopContainer>
          <div style={{ flexDirection: "row" }}>
            {props.ingredients.map((item) => (
              <Ingredient key={item}>{item}</Ingredient>
            ))}
          </div>
        </MacroContainer>
        <StyledCompareTitle>{"Sources"}</StyledCompareTitle>
        <StyledSourceContainer>
          {props.sources.map((item) => (
            <StyledSourceLink
              onClick={() =>
                window.open(item.link,'_blank')
              }
            >
              {item.title} 
              <StyledLinkImg src="/assets/link.svg"></StyledLinkImg>
            </StyledSourceLink>
          ))}
        </StyledSourceContainer>
        <DataContainer>
          <StyledCompareTitle>Provided by wemuda</StyledCompareTitle>
          <StyledWemudaImg
            onClick={() => {
              window.location.href = "https://wemuda.com";
              
            }}
            src="/assets/wemuda-logo.png"
          ></StyledWemudaImg>
        </DataContainer>
      </InnerContainer>

  );
}

const InnerContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;



const StyledWemudaImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  z-index: 99;
  margin-top: 20px;
  cursor: pointer;
`;

const StyledPaperBox = styled.div`
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.2);
  width: 94%;
  align-self: center;
  height: 120px;
  margin-top: -2.3em;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  justify-content: center;
`;

const MacroContainer = styled.div`
  z-index: 999999 !important;
  background-color: rgba(0, 0, 0, 0.2);
  width: 94%;
  align-self: center;
  margin-top: 0.7em;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
`;

const StyledPaperBoxTitle = styled.div`
  color: #fff;
  font-size: 1.9em;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: MarkPro-Bold;
`;

const StyledPaperBoxAmountSubtitle = styled.div`
  font-size: 0.8em;
  color: #fff;
  margin-top: 2px;
  font-family: MarkPro-Medium;
`;

const StyledMacroTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

const StyledMacroTitle = styled.div`
  font-family: MarkPro-Bold;
  font-size: 1em;
`;

const StyledMacroServingSize = styled.div`
  font-family: MarkPro-Medium;
  font-size: 0.8em;
`;

const MacroRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
`;

const MacroTitle = styled.div`
  font-family: MarkPro-Medium;
  font-size: 0.8em;
`;

const MacroValue = styled.div`
  font-family: MarkPro-Medium;
  font-size: 0.8em;
`;

const Ingredient = styled.div`
  font-family: MarkPro-Bold;
  font-size: 0.8em;
  display: inline-block;
  margin-right: 0.3em;
  color: "#fff"
`;

const StyledCompareTitle = styled.div`
  margin-top: 2em;
  font-size: 1em;
  color: #313131;
  font-family: MarkPro-Bold;
`;

const StyledSourceContainer = styled.div`
  margin-top: 0.5em;
  width: 94%;
  color: #4b4b4b;
  margin-bottom: 0.5em;
`;

const StyledSourceLink = styled.div`
  width: 100%;
  color: #fff;
  height: 58px;
  margin-top: 0.7em;
  border-radius: 20px;

  box-sizing: border-box;
  display: flex;
  font-family: "MarkPro-Medium";
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 0.7rem;
  font-weight: 900;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
`;

const StyledLinkImg = styled.img`
  width: 14px;
  height: 14px;
  color: #fff;
  margin-left: 5px;
`;

const DataContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
