import { wrap as wrapImg, burger as burgerImg } from "./images/index"
 
//burger
var burger_macros = [
	{
		title: "Kcal",
		value: "189",
	},
	{
		title: "Carbohydrates",
		value: "28.4g",
	},
	{
		title: "  Sugar",
		value: "4.6g",
		extended: true,
	},
	{
		title: "  Dietary fiber",
		value: "4.1g",
		extended: true,
	},
	{
		title: "Fat",
		value: "3.9",
	},
	{
		title: "  Saturated fat",
		value: "0.4",
		extended: true,
	},
	{
		title: "Protein",
		value: "9.3",
	},
	{
		title: "Salt",
		value: "0.5",
	},
];

var burger_ingredients = [
	"Brioche burger bun,",
	"Iceberg salad,",
	"Mayonnaise,",
	"Shiracha sauce,",
	"Tempeh patty,",
	"Teriyaki sauce,",
	"Tomato slice",
	"Pickled cucumber",
];

var burger_sources = [
	{
		title: " Den Store Klimadatabase, Concito",
		link: "https://denstoreklimadatabase.dk/?fbclid=IwAR1C-etB8IMhN9FHlWblYVBD8vbytmzUgHj6zxxdDvoMj5UtI_hoDKmfalk"
	}
]

const burger = { co2: "1.92 CO2eq/kg", serving: "460 g CO2eq. per serving", ingredients: burger_ingredients, macros: burger_macros, sources: burger_sources, img: burgerImg }



//Wraps

var wrap_macros = [
	{
		title: "Kcal",
		value: "184",
	},
	{
		title: "Carbohydrates",
		value: "13g",
	},
	{
		title: "  Sugar",
		value: "1.5g",
		extended: true,
	},
	{
		title: "  Dietary fiber",
		value: "1.2g",
		extended: true,
	},
	{
		title: "Fat",
		value: "20.8",
	},
	{
		title: "  Saturated fat",
		value: "1.8",
		extended: true,
	},
	{
		title: "Protein",
		value: "4.6",
	},
	{
		title: "Salt",
		value: "0.2",
	},
];

var wrap_ingredients = [
	"Salad,",
	"Cabbage mix,",
	"Chickpeas,",
	"Edamame beans,",
	"Chili mayo,",
	"Wrap bread,",
	"Falafel"
];

var wrap_sources = [
	{
		title: " Den Store Klimadatabase, Concito",
		link: "https://denstoreklimadatabase.dk/?fbclid=IwAR1C-etB8IMhN9FHlWblYVBD8vbytmzUgHj6zxxdDvoMj5UtI_hoDKmfalk"
	}
]

const wrap = { co2: "0.58 kg CO2", serving: "eq. per serving (430 g)", ingredients: wrap_ingredients, macros: wrap_macros, sources: wrap_sources, img: wrapImg }













export { burger, wrap }